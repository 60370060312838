import Vue from 'vue'
import axios from 'axios'
import JSONBIG from 'json-bigint'
//请求地址

// const BASE_URL = 'http://192.168.0.100:7000'
// const BASE_URL = 'https://za46496893.yicp.fun/base'

// const BASE_URL = 'https://api.linyiwenlv.cn/base'
const BASE_URL = 'https://api.lnnysy.top/base'

// const BASE_URL = 'http://192.168.0.116:8090'
// const BASE_URL = 'http://192.168.1.3:8051'
// const BASE_URL = 'http://192.168.1.5:8090'
//图片请求地址
const IMAGE_BASE_URL = 'http://39.153.185.9:8090'
// const IMAGE_BASE_URL = 'http://192.168.1.3:8051'

const instance = axios.create({
  // 基地址
  // baseURL: 'http://118.25.188.181:8089/',
  baseURL: BASE_URL,
  // baseURL: 'http://localhost/dev-api/',
  // 设置 超时  时间 ，5000毫秒
  timeout: 5000,
  // 转换响应数据格式(覆盖默认转换方式) transformResponse执行 ===> 响应拦截器  ===>  then和catch
  transformResponse: [data => {
    // data 其实就是原始的json字符串，删除的时候后台无响应，data === null
    try {
      const jsonObject = JSONBIG.parse(data)
      return jsonObject
    } catch (e) {
      return data
    }
  }]
})

// 把 axios实例 挂载到 Vue 原型 上，今后 在 组件中 直接 通过 this.$http 直接 调用 请求 方法
Vue.prototype.$http = instance
Vue.prototype.IMAGE_BASE_URL = IMAGE_BASE_URL
